import {auth} from './services/Firebase';

export function getTeamFromUser(user) {

	let team = {
		name: '',
		id: ''
	}

	const uid = ( user.uid ? user.uid : user );

	switch(uid) {
		case 'atxVRnW0ZOMUi8TkdDyAktcx52z2':
			team.name = 'FC Boston';
			team.id = 'fcb';
			break;
		case 'GZtI6uuSGYesYLQe6wFz6WJHBeq1':
			team.name = 'Wolverines';
			team.id = 'wolverines';
			break;
		case '1Wu2sl0GjDZ0rrsTLb7BxEzW1mD2':
			team.name = 'Game Master';
			team.id = 'gamemaster';
			break;
		default:
			team.name = 'Unknown';
			team.id = 'unknown';
			break;
	}
	return team;
}

export function isGameMaster() {
	const user = auth().currentUser;
	return user.uid === '1Wu2sl0GjDZ0rrsTLb7BxEzW1mD2';
}

// Hardcoded
export function getUserIdFromTeam(teamId) {

	switch(teamId) {
		case 'fcb':
			return 'atxVRnW0ZOMUi8TkdDyAktcx52z2';
			//break;
		case 'wolverines':
			return 'GZtI6uuSGYesYLQe6wFz6WJHBeq1';
			//break;
		case 'gamemaster':
			return '1Wu2sl0GjDZ0rrsTLb7BxEzW1mD2';
			//break;
	}
	return null;
}


export function getRandomRoll(min= 1, max= 101) {
	return Math.floor(Math.random() * (max - min) + min);
}

export function getLatestRollForUser(rolls, uid) {
	return rolls.find((roll) => {
		return roll.uid === uid;
	});
}


export function leadingZero(num) {
	if(num >=0 && num <=9) {
		return `0${num}`;
	}
	return num.toString();
}

export function wait(ms = 0) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export const SIMULATED_WAIT_TIME = 2000;