import React from 'react';
import PropTypes from 'prop-types';

import { wait,SIMULATED_WAIT_TIME } from './helpers';

class RollButton extends React.Component{

	constructor(props) {
		super(props);

		this.state = {
			disabled : false,
		}

		this.handleRoll = this.handleRoll.bind(this);
	}

	async handleRoll(e) {
		e.preventDefault();
		this.props.handleRoll();

		this.setState({
			disabled: true
		});

		await wait(SIMULATED_WAIT_TIME);

		this.setState({disabled: false});
	}

	render() {
		return (
			<button type="button" id="roll-btn" onClick={ this.handleRoll } disabled={ this.state.disabled }>
				<span>Kast terningerne</span>
			</button>
		)
	}
}


RollButton.propTypes = {
	handleRoll: PropTypes.func.isRequired,
};

export default RollButton;