import React from 'react';
import packageJson from '../package.json';

class AppVersion extends React.Component{

	render() {
		return (
			<div className="version-app">SMRPG // Dice App version <strong>{ packageJson.version }</strong></div>
		)
	}
}

export default AppVersion;