import React from 'react';
import PropTypes from 'prop-types';
import {getTeamFromUser,leadingZero,wait,SIMULATED_WAIT_TIME} from './helpers';

class LogItem extends React.Component{

	constructor(props) {
		super(props);

		this._isMounted = false;

		this.state = {
			awaiting: true
		}
	}

	async componentDidMount() {
		this._isMounted = true;
		await wait(SIMULATED_WAIT_TIME);
		if(this._isMounted) {
			this.setState({awaiting: false});
		}
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if(!nextProps.awaiting) {
			return false;
		}
		return (this.state.awaiting !== nextState.awaiting);
	}

	render() {

		const { data } = this.props;

		const team = getTeamFromUser( data.uid );

		let className = `roll ${team.id}`;

		const date = new Date(data.now);
		const dateString = `${date.getFullYear()}-${leadingZero(date.getMonth()+1)}-${leadingZero(date.getDate())} ${leadingZero(date.getHours())}:${leadingZero(date.getMinutes())}:${leadingZero(date.getSeconds())}`;

		if(this.state.awaiting && this.props.awaiting) {

			className = `${className} awaiting`;

			return (
				<li className={className}>
					<div className="throw"><span className="team-name">{ team.name }</span> slår med terningerne</div>
					<span className="datetime">{ dateString }</span>
				</li>
			);
		}

		if(data.hidden && !this.props.isUserTheGameMaster) {
			className = `${className} hidden`;

			return (
				<li className={className}>
					<div className="throw"><span className="team-name">{ team.name }</span> slog et <span className="value">hemmeligt slag</span></div>
					<span className="datetime">{ dateString }</span>
				</li>
			);
		}
		if(data.hidden && this.props.isUserTheGameMaster) {
			className = `${className} hidden-gm`;

			return (
				<li className={className}>
					<div className="throw"><span className="team-name">{ team.name }</span> slog <span className="value">{ leadingZero(data.roll) }</span>&nbsp;&nbsp;<strong>(Hemmeligt)</strong></div>
					<span className="datetime">{ dateString }</span>
				</li>
			);
		}

		return (
			<li className={className}>
				<div className="throw"><span className="team-name">{ team.name }</span> slog <span className="value">{ leadingZero(data.roll) }</span></div>
				<span className="datetime">{ dateString }</span>
			</li>
		);
	}
}

LogItem.propTypes = {
	data: PropTypes.object.isRequired,
	awaiting: PropTypes.bool,
	isUserTheGameMaster: PropTypes.bool
};

export default LogItem;