//import Firebase from "firebase";
import Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
	apiKey: "AIzaSyDwV8Ura43ifUb-RuY1inFyuSHpnnSVyPk",
	authDomain: "smrpg-dice.firebaseapp.com",
	databaseURL: "https://smrpg-dice.firebaseio.com"
};

Firebase.initializeApp(config);

export function signin(email, password) {
	return auth().signInWithEmailAndPassword(email, password);
}

export function signout() {
	return auth().signOut()
}

export const auth = Firebase.auth;
export const db = Firebase.database();