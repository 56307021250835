import React from 'react';
import PropTypes from 'prop-types';

import { isGameMaster } from './helpers';
import LogItem from './LogItem';

class Log extends React.Component{

	constructor(props){
		super(props);

		this.state = {
			firstRender: true,
			isUserTheGameMaster: isGameMaster(),
		};

		this.deleteAllRolls = this.deleteAllRolls.bind(this);

	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevState.firstRender) {
			this.setState({ firstRender: false })
		}
	}

	deleteAllRolls(e) {
		e.preventDefault();
		if(window.confirm('Er du sikker?')) {
			this.props.deleteRolls();
		}
	}

	render() {

		const { isUserTheGameMaster, firstRender } = this.state;
		const { rolls } = this.props;

		const rollKeys = Object.keys(rolls);

		const buttonDelete = ( isUserTheGameMaster ? <button type="button" id="delete-all-btn" onClick={ this.deleteAllRolls }><span>[Slet alle kast]</span></button> : null );

		return (
			<div id="log">
				<h2 className="heading">Seneste terningekast ({ rollKeys.length }) {buttonDelete}</h2>
				<ul className="rolls">
					{
						rollKeys.map((index) => {
							return <LogItem
								key={ rolls[index].roll_id }
								data={ rolls[index] }
								awaiting={ !firstRender }
								isUserTheGameMaster={ isUserTheGameMaster }
							/>
						})
					}
				</ul>
			</div>
		)
	}

}


Log.propTypes = {
	rolls: PropTypes.array.isRequired,
	deleteRolls: PropTypes.func.isRequired,
};

export default Log;