import React from 'react';
import PropTypes from 'prop-types';

class Dice extends React.Component{

	constructor(props) {
		super(props);

		this.state = {
			value: null,
			dice1DefaultAnimation: true,
			dice2DefaultAnimation: true,
		};
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return this.props.roll_id !== nextProps.roll_id;
	}

	// Store the previous face for each die, so we can determine if we need to re-animate the die
	static getDerivedStateFromProps(props, state) {

		// Previous Roll
		let prevFaceDice1 = ( !state.value ? state.value : Math.floor(state.value / 10) );
		prevFaceDice1 = ( prevFaceDice1 >= 10 ? 0 : prevFaceDice1 );
		const prevFaceDice2 = ( !state.value ? state.value : state.value % 10 );

		// Current Roll
		let curFaceDice1 = ( !props.value ? props.value : Math.floor(props.value / 10) );
		curFaceDice1 = ( curFaceDice1 >= 10 ? 0 : curFaceDice1 );
		const curFaceDice2 = ( !props.value ? props.value : props.value % 10 );

		return {
			dice1DefaultAnimation: ( prevFaceDice1 === curFaceDice1 || !props.value ? !state.dice1DefaultAnimation : state.dice1DefaultAnimation ),
			dice2DefaultAnimation: ( prevFaceDice2 === curFaceDice2 || !props.value ? !state.dice2DefaultAnimation : state.dice2DefaultAnimation ),
			value: props.value,
		};
	}

	render() {

		let {value} = this.props;
		if(!value) {
			value = null;
		}

		let faceDice1 = ( !value ? value : Math.floor(value / 10) );
		faceDice1 = ( faceDice1 >= 10 ? 0 : faceDice1 );
		const faceDice2 = ( !value ? value : value % 10 );

		return (
			<div id={ this.props.id } className="dice-wrapper">

				<h2 className="team-name">{ this.props.teamName }</h2>

				<div className="dice dice-1 js-rolling" data-face={ faceDice1 } data-default-animation={ this.state.dice1DefaultAnimation }>
					<div className="dice-inner">
						<figure className="face face-0" data-face="00" />
						<figure className="face face-1" data-face="10" />
						<figure className="face face-2" data-face="20" />
						<figure className="face face-3" data-face="30" />
						<figure className="face face-4" data-face="40" />
						<figure className="face face-5" data-face="50" />
						<figure className="face face-6" data-face="60" />
						<figure className="face face-7" data-face="70" />
						<figure className="face face-8" data-face="80" />
						<figure className="face face-9" data-face="90" />
					</div>
				</div>
				<div className="dice dice-2 js-rolling" data-face={ faceDice2 } data-default-animation={ this.state.dice2DefaultAnimation }>
					<div className="dice-inner">
						<figure className="face face-0" data-face="0" />
						<figure className="face face-1" data-face="1" />
						<figure className="face face-2" data-face="2" />
						<figure className="face face-3" data-face="3" />
						<figure className="face face-4" data-face="4" />
						<figure className="face face-5" data-face="5" />
						<figure className="face face-6" data-face="6" />
						<figure className="face face-7" data-face="7" />
						<figure className="face face-8" data-face="8" />
						<figure className="face face-9" data-face="9" />
					</div>
				</div>
			</div>
		);
	}
}

Dice.propTypes = {
	teamName: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	value: PropTypes.number,
	rollId: PropTypes.string,
};

export default Dice;