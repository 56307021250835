import React from 'react';

import { auth, db } from './services/Firebase';
import { getTeamFromUser, getRandomRoll, getLatestRollForUser, getUserIdFromTeam } from './helpers';

import Log from './Log';
import Dice from './Dice';

import RollButton from './RollButton';
import Login from './Login';
import AppVersion from './AppVersion';
import Header from './Header';

class App extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			authenticated: false,
			loading: true,
			rolls: [],
		};

		this.refHiddenCB = React.createRef();

		this.getRolls = this.getRolls.bind(this);
		this.handleRoll = this.handleRoll.bind(this);
		this.deleteRolls = this.deleteRolls.bind(this);
	}

	componentDidMount() {

		auth().onAuthStateChanged((user) => {
			if(user) {

				this.setState({
					authenticated: true,
					loading: true,
				});

				this.getRolls();

			} else {
				this.setState({
					authenticated: false,
					loading: false,
				});
			}
		});
	}

	getRolls() {

		db.ref("roll").limitToLast(100).on("value", (snapshot) => {

			let allRolls = [];
			snapshot.forEach((snap) => {
				allRolls.push( snap.val() );
			});

			allRolls.reverse();

			this.setState({
				rolls: allRolls,
				loading: false,
			});
		});
	}

	handleRoll() {

		const user = auth().currentUser;
		if(!user) {
			return false;
		}

//		const uid = getUserIdFromTeam('wolverines');
//		const uid = getUserIdFromTeam('fcb');
		const uid = user.uid;
		const roll = getRandomRoll();
		const now = Date.now();
		const roll_id = `roll-${now}-${uid}`;
		const hidden = this.refHiddenCB.current.checked;

		db.ref(`roll/${roll_id}`)
			.set({
				roll,
				roll_id,
				uid,
				now,
				hidden
			})
			.then(_ => {
				//this.setState({ timestamp: roll_id });
			});
	}

	deleteRolls() {

		const user = auth().currentUser;
		if(!user) {
			return false;
		}

		const team = getTeamFromUser(user);
		if(team.id === 'gamemaster') {
			db.ref("roll").remove();
		}
	}

	render() {

		if(this.state.loading) {
			return (
				<section className="smrpg-dice-app">
					<div id="canvas">
						<div className="loading">LOADING</div>
						<AppVersion />
					</div>
				</section>
			)
		}

		if(!this.state.authenticated) {
			return (
				<section className="smrpg-dice-app">
					<div id="canvas">
						<Login />
						<AppVersion />
					</div>
				</section>
			);
		}

		// Ge the user and the team
		const user = auth().currentUser;
		const team = getTeamFromUser(user);

		const { rolls } = this.state;

		// FCB Roll
		const fcbLatestRoll = getLatestRollForUser(rolls, getUserIdFromTeam('fcb'));
		const fcbRollId = ( fcbLatestRoll ? fcbLatestRoll.roll_id : null );
		const fcbRollValue = ( fcbLatestRoll && (!fcbLatestRoll.hidden || team.id === 'gamemaster') ? fcbLatestRoll.roll : null ); // Always allow gamemasters

		// Wolverines Roll
		const wolverinesLatestRoll = getLatestRollForUser(rolls, getUserIdFromTeam('wolverines'));
		const wolverinesRollId = ( wolverinesLatestRoll ? wolverinesLatestRoll.roll_id : null );
		const wolverinesRollValue = ( wolverinesLatestRoll && (!wolverinesLatestRoll.hidden || team.id === 'gamemaster') ? wolverinesLatestRoll.roll : null ); // Always allow gamemasters

		return (
			<section className="smrpg-dice-app">
				<div id="canvas">

					<Header team={ team } />

					<div className="the-dice">

						<Dice
							key="fcb"
							teamName="FC Boston"
							id="fcb"
							value={ fcbRollValue }
							roll_id={ fcbRollId }
						/>
						<Dice
							key="wolverines"
							teamName="Wolverines"
							id="wolverines"
							value={ wolverinesRollValue }
							roll_id={ wolverinesRollId }
						/>

					</div>

					<RollButton
						handleRoll={ this.handleRoll }
					/>

					<div className="checkbox">
						<input id="hidden-roll" type="checkbox" defaultChecked={true} ref={ this.refHiddenCB } />
						<label htmlFor="hidden-roll">Hemmeligt slag (kun synligt for GM)</label>
					</div>

					<Log
						rolls={ rolls }
						deleteRolls={ this.deleteRolls }
					/>

					<AppVersion />
				</div>

			</section>
		);
	}
}

export default App;