import React from 'react';

import { signout } from './services/Firebase';

class Logout extends React.Component{

	constructor(props) {
		super(props);

		this.handleLogout = this.handleLogout.bind(this);
	}

	handleLogout(e) {
		e.preventDefault();
		try {
			signout();
		} catch (error) {
			console.log('Logout.handleLogout()');
		}
	}

	render() {

		return (
			<button type="button" id="logout-btn" onClick={ this.handleLogout }>
				<span>Logout ></span>
			</button>
		)
	}
}

export default Logout;