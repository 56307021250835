import React from 'react';
import { signin } from './services/Firebase';

class Login extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			error: null,
			email: '',
			password: '',
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	async handleSubmit(e) {
		e.preventDefault();
		this.setState({ error: '' });
		try {
			await signin(this.state.email, this.state.password);
		} catch (error) {
			this.setState({ error: error.message });
		}
	}

	render() {
		return (
			<div className="login">
				<h1 className="heading">SMRPG // Login</h1>
				<form autoComplete="on" onSubmit={this.handleSubmit}>

					<input className="input-field" placeholder="Email" name="email" type="email" onChange={this.handleChange} value={this.state.email}></input>
					<input className="input-field" placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password} type="password"></input>

					<button className="btn">Login</button>

					{this.state.error ? <p className="errors">{this.state.error}</p> : null}

				</form>
			</div>
		)
	}
}

export default Login;