import React from 'react';
import PropTypes from 'prop-types';

import Logout from './Logout';

class Header extends React.Component{

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return false;
	}

	render() {
		return (
			<header className="the-header">
				<div className="header-inner">
					<div className={`user-info ${this.props.team.id}`}>
						Du er logget ind som <span className="team-name">{ this.props.team.name }</span>
					</div>
					<Logout />
				</div>
			</header>
		)
	}
}


Header.propTypes = {
	team: PropTypes.object.isRequired,
};

export default Header;